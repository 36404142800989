import React, { useEffect, useState } from 'react'
import newEnquiryServices from '../newEnquiryServices';
import AppLayout from '../../../../layout/components/AppLayout';
import { Delete } from '@mui/icons-material';

function NewEnquiryListPage() {
    const [newEnquiryList,setNewEnquiryList] = useState(null);
    const [loading,setLoadingStatus] = useState(false);
    const getNewEnquiryList = () =>{
        newEnquiryServices.getList().then(res=>{
            setNewEnquiryList(res.data);
        }).catch(()=>{

        }).finally(()=>{

        })
    }
    
    useEffect(()=>{
        getNewEnquiryList();
    },[])

    const removeEnquery = (title) =>{
        newEnquiryServices.remove(title).then(res=>{
            getNewEnquiryList()
        }).catch(()=>{

        }).finally(()=>{

        })
    }

    return (
        <AppLayout>
            <div className='container-fluid'>
                <div className='col-md-12'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Query</th>
                                <th>Count</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {newEnquiryList && newEnquiryList.map((newEnquiry,i)=>
                                <tr key={i}>
                                    <td>{newEnquiry.title}</td>
                                    <td>{newEnquiry.total}</td>
                                    <td><button className='btn btn-danger' onClick={()=>removeEnquery(newEnquiry.title)}><Delete/></button></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </AppLayout>
    )
}

export default NewEnquiryListPage