import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import TextMessageForm from '../components/TextMessageForm';
import InteractiveReplyMessageForm from '../components/InteractiveReplyMessageForm';
import { useParams } from 'react-router-dom';
import responseMessageServices from '../responseMessageServices';
import { useAlert } from '../../../../context/AlertContext';
import InteractiveListMessageForm from '../components/InteractiveListMessageForm';
import LocationMessageForm from '../components/LocationMessageForm';
import ImageMessageForm from '../components/ImageMessage';



function ResponseMessageFormPage() {

    const [messageType,setMessageType] = useState(null);
    const {id} = useParams();
    const [data,setData] = useState(null);
    const {AppAlert} = useAlert();

    useEffect(()=>{
        if(id){
            responseMessageServices.getById(id).then(res=>{
                let _data = {id:id,title:res.data.title}
                _data.formValues = JSON.parse(res.data.formValues)
                setData(_data);
                setMessageType(res.data.messageType);
            }).catch(error=>{
                AppAlert(error.msg,"error")
            })
        }
    },[id])

    

    return (
        <AppLayout>
            <div className='container-fluid'>
                {messageType == null &&
                    <div className='row mt-5'>
                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("text")} className='text-center bg-primary text-white rounded p-3 m-2' type={"button"}  >Text</div>
                        </div>                        
                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("interactiveReply")} className='text-center bg-primary text-white rounded p-3 m-2' type={"button"}  >Intractive Reply</div>
                        </div>

                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("interactiveList")} className='text-center bg-primary text-white rounded p-3 m-2' type={"button"}  >Intractive List</div>
                        </div>

                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("locationMessage")} className='text-center bg-primary text-white rounded p-3 m-2' type={"button"}  >Location</div>
                        </div>

                        <div className='col-md-2'>
                            <div onClick={()=>setMessageType("imageMessage")} className='text-center bg-primary text-white rounded p-3 m-2' type={"button"}  >Image</div>
                        </div>
                    </div>
                }
                
                <div className='row'>
                    <div className='col-md-12'>
                        {messageType === "text" && <TextMessageForm data={data}/>}
                        {messageType === "interactiveReply" && <InteractiveReplyMessageForm data={data} />}
                        {messageType === "interactiveList" && <InteractiveListMessageForm data={data} />}
                        {messageType === "locationMessage" && <LocationMessageForm data={data} />}
                        {messageType === "imageMessage" && <ImageMessageForm data={data} />}

                    </div>
                </div>
            </div>
        </AppLayout>
    )
}

export default ResponseMessageFormPage