import React, { useEffect, useState } from 'react'
import responseMessageServices from '../responseMessageServices';
import { useAlert } from '../../../../context/AlertContext';
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function TextMessageForm({data}) {
	const {AppAlert} = useAlert();
	const [title,setTitle] = useState(""); 
	const [messageBody,setMessageBody] = useState("");
	const [saving,setSavingStatus] = useState(false);
	
	const autoFillData = ()=>{
		if(data){
			setTitle(data.title)
			setMessageBody(data.formValues.messageBody);
		}
	}
	useEffect(()=>{
		autoFillData();
	},[data])

	const prepareTextResponse = () =>{
		let _responseMessage = {
			"preview_url": true,
			"body": messageBody
		}
		return _responseMessage;
	}

	const prepareWhatsAppResponse = () =>{
		let _whatsAppResponse = {
			"messaging_product": "whatsapp",
    		"recipient_type": "individual",
			"type": "text",
			"text":prepareTextResponse()
		}
		return JSON.stringify(_whatsAppResponse);
	}

	const prepareFormValues = () =>{
		let _formValues = {
			messageBody:messageBody
		}
		return JSON.stringify(_formValues);
	}

	const navigate = useNavigate();
	const onSubmit = () =>{

		let payload = {
			"title":title,
			"messageType":"text",
			"whatsAppResponse": prepareWhatsAppResponse(),
			"formValues":prepareFormValues()
		}

		if(data){
			payload.id = data.id;
		}
		setSavingStatus(true);
		responseMessageServices.save(payload).then(res=>{
			AppAlert(res.msg)
			if(data){
				navigate("/admin/response-message/form/"+data.id)
			} else{
				navigate("/admin/response-message/form/"+res.id)
			}
			
		}).catch(error=>{
			AppAlert(error.msg,"error");
		}).finally(()=>{
			setSavingStatus(false);
		})
	}

    return (
		<div className='row'>
			<div className='col-md-9'>
				<div className='card'>
					<div className='card-header'>Text Message Response</div>
					<div className='card-body'>
						<div className='row'>
							<div className='col-md-6'>
								<div className="form-floating mb-3">
									<input type="text" className="form-control" placeholder="" value={title}  onChange={(e)=>setTitle(e.target.value)}/>
									<label>Message Title (For Admin / Manager Use) *</label>
								</div>
							</div>
							<div className='col-md-12'>
							<div className="form-floating">
								<textarea className="form-control" placeholder="" style={{height:"100%"}} 
									value={messageBody}  onChange={(e)=>setMessageBody(e.target.value)}
								></textarea>
								<label>Message *</label>
							</div>
							</div>
						</div>
					</div>
					<div className='card-footer'>
						{saving ? 
							<LinearProgress/>
							:
							<button disabled={title == "" || messageBody == ""} className='btn btn-primary float-end' onClick={onSubmit} >Save</button>
						}
					</div>
				</div>
			</div>
			<div className='col-md-3'>
				<h3 className='mx-3'>Preview</h3>
				<div className='bg-light border border-dark rounded p-2 mx-3 ps-5' style={{height:"70vh"}}>
					<div className='card'>
						<div className='card-body'>
							{messageBody}
						</div>
					</div>
				</div>
			</div>
		</div>
    	
    )
}

export default TextMessageForm