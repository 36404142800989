import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import businessServices from '../businessServices'
import { useAlert } from '../../../../context/AlertContext'
import TextInline from '../../../../components/InlineEdit/TextInline'
import useSystemMasterList from '../../../../hooks/useSystemMasterList'
import AwsImageInline from '../../../../components/InlineEdit/AwsImageInline'
import { Switch } from '@mui/material'
import moment from 'moment'

function BusinessDetails() {
    const {AppAlert} = useAlert();
    const [masters,getMasterLabel] = useSystemMasterList(['business-type'])
    const [businessDetails, setBusinessDetails] =  useState(null)
    
    
    const getBusinessDetails = () =>{
        businessServices.getDetails().then(res=>{
            setBusinessDetails(res.data);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        })
    }

    

    useEffect(()=>{
        getBusinessDetails();
        
    },[])

    const handleStatusChange = (e,module) =>{
            let payload ={};
            payload[module] = e.target.checked ? 1 : 0;
            console.log(payload);
            businessServices.save(payload).then(res=>{
                getBusinessDetails()
            })
    }

    
    

    return (
        <AppLayout>
            <div className='container-fluid'>
                {businessDetails && masters &&
                <div className='row'>
                    <div className='col-md-3'>
                        <AwsImageInline
                            label="Business Logo"
                            name="logo"
                            value={businessDetails.logo}
                            editId={businessDetails.id}
                            updateInline={businessServices.updateInline}
                            path="business/logo"
                            fileId={"logo"}
                            onSuccess={getBusinessDetails}
                        />
                    </div>
                    <div className='col-md-9'>
                        
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <th>Business Name</th>
                                    <td>
                                        <TextInline name="title" value={businessDetails.title} editId={businessDetails.id} updateInline={businessServices.updateInline} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Busines Owner</th>
                                    <td>
                                        <TextInline name="businessOwner" value={businessDetails.businessOwner} editId={businessDetails.id} updateInline={businessServices.updateInline} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{businessDetails.businessEmail}</td>
                                </tr>
                                <tr>
                                    <th>Phone </th>
                                    <td>{businessDetails.businessPhone}</td>
                                </tr>
                                <tr>
                                    <th>Business Type </th>
                                    <td>{ getMasterLabel("business-type",businessDetails.businessType) }</td>
                                </tr>
                                <tr>
                                    <th>GST</th>
                                    <td>
                                        <TextInline name="gst" value={businessDetails.gst} editId={businessDetails.id}  updateInline={businessServices.updateInline} />
                                    </td>
                                </tr>
                                <tr>
                                    <th>PAN</th>
                                    <td>
                                        <TextInline name="pan" value={businessDetails.pan} editId={businessDetails.id} updateInline={businessServices.updateInline} />
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                }
            </div>
        </AppLayout>
        
    )
}

export default BusinessDetails