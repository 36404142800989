import React from 'react'
import { Route, Routes } from 'react-router'
import SystemMasterListPage from '../app/master/pages/SystemMasterListPage'

// User 
import UserListPage from '../app/admin/user/pages/UserListPage'
import UserDetailsPage from '../app/admin/user/pages/UserDetailsPage'
import UserRoleListPage from '../app/admin/user/pages/UserRoleListPage'

//Business
import BusinessDetails from '../app/admin/business/pages/BusinessDetails'
import ManagerRoutes from './ManagerRoutes'


function AdminRoutes() {
  return (
    <>
      <Routes>
        {/* Business Details */}
        <Route path="/business/details" element={<BusinessDetails />}></Route>
        {/* User */}
        <Route path="/user/list" element={<UserListPage /> }></Route>
        <Route path="/user/details/:id" element={<UserDetailsPage /> }></Route>
        <Route path="/user/role" element={<UserRoleListPage /> }></Route>
        {/* Masters */}
        <Route path="/master/list" element={ <SystemMasterListPage /> } />
      </Routes>

      <ManagerRoutes/>
    </>
  )
}

export default AdminRoutes