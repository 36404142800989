import React, { useEffect, useState } from 'react'
import AppLayout from '../../../layout/components/AppLayout';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';


import ConfirmDialog from '../../../components/ConfirmDialog';
import { Alert, LinearProgress, Snackbar } from '@mui/material';
import { Link } from 'react-router-dom';
import systemMasterServices from '../systemMasterServices';
import SystemMasterForm from '../components/SystemMasterForm';
import { useAlert } from '../../../context/AlertContext';




const CellActions = (props) =>{
  
  return(
    <>
      <SystemMasterForm masterName={props.masterName} AppAlert={props.AppAlert} refreshList={props.refreshList} data={props.data}/>
      <ConfirmDialog id={props.data.id} confirm={ ()=>props.remove(props.data.id,props.masterName)} >Are you Sure You want to remove</ConfirmDialog>
    </>
  )
}




const SystemMasterListPage = () => {


  const [rowData,setRowData] = useState(null);
  const [listName,setListName] = useState("business-type");
  const [loading, setLoadingStatus] = useState(false);
  const [columnDefs,setColumnDefs] = useState(null);
  const {AppAlert} = useAlert();


  const [defaultColDef] = useState({
    floatingFilter: true,
  })
  

  const getData =  () => {
    setLoadingStatus(true);
    systemMasterServices.getData(listName).then(res=>{
      setRowData(res.data);
      switch(listName) {
        case "business-type1": // fortesting
            setColumnDefs(branchColDefs)
          break;
        default:
            setColumnDefs(commonColDefs);
      } 
      setLoadingStatus(false);
    })
  }

  const remove = (id,masterName)=>{
    let payload = {id,masterName};
    systemMasterServices.remove(payload).then(res=>{
      getData();

      AppAlert("Removed Successfully","error")
    }).catch(error=>{
        AppAlert(error.msg,"error")
    })
  }

  const updateData =(params) =>{
    console.log(params);
  }


  const commonColDefs = [
    { field:"id",sortable: true, filter:true, },
    { field:"title",sortable: true, filter:true, editable: true, colId:"title"},
    { field:"id", colId:"id", cellRenderer: CellActions, headerName: 'Action', cellRendererParams: {
      masterName:listName,
      refreshList:getData,
      AppAlert:AppAlert,
      remove:remove
    }
    },
  ]


  const branchColDefs = [
    { field:"id",sortable: true, filter:true, },
    { field:"title",sortable: true, filter:true, editable: true, colId:"title"},
    { field:"state",sortable: true, filter:true, editable: true, colId:"state"},
    { field:"address",sortable: true, filter:true, editable: true, colId:"address"},

    { field:"id", colId:"id", cellRenderer: CellActions, headerName: 'Action', cellRendererParams: {
      masterName:listName,
      refreshList:getData,
      AppAlert:AppAlert,
      remove:remove
    }
    },
  ]

  let systemMastersList =[
    "business-type","gender","message-type",
  ]

  const getHumanTitle = (master) =>{
      let ar = master.split("-");
      let newAr = ar.map(string=>
        string.charAt(0).toUpperCase() + string.slice(1)
      )
      return newAr.join(" ");
  }

  useEffect(()=>{
    getData();
  },[listName])
  

  return (
      <AppLayout>
        <div className="pt-2">
          <div className="container-fluid">
              <div className="row">
                <div className="col-md-3">
                  <div className="list-group">
                    {systemMastersList.map((systemMaster,i)=>                            
                            <Link key={i} to={"#"+systemMaster} onClick={()=>setListName(systemMaster)} className={`list-group-item ${listName===systemMaster ? "active" : ""}`}>{getHumanTitle(systemMaster)}</Link>
                            
                        )
                    }
                  </div>
                </div>
                <div className="col-md-9">
                      <>
                        {listName && 
                          <div className="row">
                            <div className="col-md-6 mt-2">
                              <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                  <li className="breadcrumb-item" aria-current="page">System Masters</li>
                                  <li className="breadcrumb-item active" aria-current="page">{listName.charAt(0).toUpperCase() + listName.slice(1).replace("-"," ")}</li> 
                                </ol>
                              </nav>
                            </div>
                            <div className="col-md-6">
                                <SystemMasterForm AppAlert={AppAlert} masterName={listName} refreshList={getData}/>
                            </div>
                          </div>
                        }
                        <div className="row">
                          <div className="col-md-12">
                            {loading &&  <LinearProgress/>}
                            {! loading && rowData==null && <h3>No data Available</h3> }
                            <div className="ag-theme-alpine" style={{height: 400}}>
                              <AgGridReact
                                  rowData={rowData}
                                  columnDefs={columnDefs}
                                  defaultColDef={defaultColDef}
                                  onCellValueChanged = {(params)=>{ updateData(params) }}
                                  >
                              </AgGridReact>
                            </div>
                          </div>
                        </div>
                        
                      </>
                    
                    
                    {/* JSON.stringify(rowData) */}
                </div>
              </div>
          </div>
        </div>
      </AppLayout>
    
  )
}

export default SystemMasterListPage;