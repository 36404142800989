import React from 'react'
import { Route, Routes } from 'react-router'
import AdminLandingPage from '../app/admin/dashboard/pages/AdminLandingPage'
import ResponseMessageListPage from '../app/admin/response/pages/ResponseMessageListPage'
import ResponseMessageFormPage from '../app/admin/response/pages/ResponseMessageFormPage'
import IntentListPage from '../app/admin/Intent/pages/IntentListPage'
import NewEnquiryListPage from '../app/admin/newEnquiry/pages/NewEnquiryListPage'
import ChatLogPage from '../app/admin/chatLog/pages/ChatLogPage'
import MediaLibraryPage from '../app/admin/response/pages/MediaLibraryPage'


function ManagerRoutes() {
  return (
    <>
      <Routes>
        {/* Admin */}
        <Route path="/" element={ <AdminLandingPage /> }></Route>
        <Route path="/admin/response-message" element={ <ResponseMessageListPage /> }></Route>
        <Route path="/admin/response-message/form" element={ <ResponseMessageFormPage /> }></Route>
        <Route path="/admin/response-message/form/:id" element={ <ResponseMessageFormPage /> }></Route>=
        <Route path="/admin/intent" element={ <IntentListPage /> }></Route>
        <Route path="/admin/new-enquiry" element={ <NewEnquiryListPage /> }></Route>
        <Route path="/admin/chat-log" element={ <ChatLogPage /> }></Route>

        <Route path="/admin/media-library" element={ <MediaLibraryPage /> }></Route>
        
      </Routes>
    </>
  )
}

export default ManagerRoutes