import api from "../../../api/api";

const getList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/response-message").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getById = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/response-message/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const save = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.post("/admin/response-message",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const updateInline = (payload) =>{
    return new Promise((resolve, reject)=>{
        api.put("/admin/response-message",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}


const remove = (id) => {
    return new Promise((resolve, reject)=>{
        api.delete("/admin/response-message/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getResponseDDList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/response-message/dd/list").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}


const markAsDefault = (id) =>{
    return new Promise((resolve, reject)=>{
        api.get("/admin/response-message/mark-default/"+id).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
          })
    })
}

const responseMessageServices = {
    getList,
    getById,
    updateInline,
    getResponseDDList,
    save,
    remove,
    markAsDefault
}
export default responseMessageServices;