import React,{useState,useEffect} from 'react'
import { useAlert } from '../../../../context/AlertContext';
import { Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import AppSelect from '../../../../components/AppInput/AppSelect';
import intentServices from '../intentServices';

function IntentForm({data,responseDDList, onSuccess}) {

    const {AppAlert} = useAlert();
    const [dialogStatus, setDialogStatus] = useState(false);
    const [intentInput,setIntentInput] = useState("");
    const [userQueryList,setUserQueryList] = useState([]);

    const [selectedResponse,setSelectedResponse] = useState(null)

    const [savingInProgress, setSavingStatus] = useState(false);

    const addNewIntent = () =>{
        setDialogStatus(true);
    }

    const addToUserQueryList = () =>{
        let _userQueryList = [...userQueryList];
        _userQueryList.push(intentInput);
        setIntentInput("");
        setUserQueryList(_userQueryList)
    }

    const removeFromUserQueryList = (i) =>{
        let _userQueryList = [...userQueryList];
        _userQueryList.splice(i,1)
        setUserQueryList(_userQueryList)
    }

    const onSubmit = () => {
        let payload = {
            userQuery : JSON.stringify(userQueryList),
            responseMessageId : selectedResponse.value,
        }
        if(data){
			payload.id = data.id;
		}
        setSavingStatus(true);
        intentServices.save(payload).then(res=>{
            AppAlert(res.msg)
            onSuccess();
            setUserQueryList([]);
            setSelectedResponse(null);
            setDialogStatus(false);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
    }
    
    const getSelectedResponse = (id) =>{
        try{
            let _selectedResponse =responseDDList.find(r => r.value === id)
            console.log(_selectedResponse)
            return _selectedResponse;
        } catch(e){
            //console.log(type,id);
            return "";
        }
    }

    const autoFillData = ()=>{
		if(data){
            setSelectedResponse(getSelectedResponse(data.responseMessageId));
            setUserQueryList( JSON.parse(data.userQuery))
		}
	}
	useEffect(()=>{
		autoFillData();
	},[data])

    return (
    <>
        {!data &&
            <button className='btn btn-primary' onClick={addNewIntent}>Add New Intent</button>
        }

        {data &&
            <button className='btn btn-primary' onClick={addNewIntent}>Edit</button>
        }

        <Dialog
                open={dialogStatus}
                keepMounted
                onClose={()=>setDialogStatus(false)}
                fullWidth={true}
                maxWidth="lg"
                aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle>Intent Form</DialogTitle>
            <DialogContent>
                <div className='row mt-1'>
                    <div className='col-md-12'>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="User Query" 
                                value={intentInput} 
                                onChange={(e)=>setIntentInput(e.target.value)}
                                onKeyUp={ (e) =>{
                                    if(e.key === 'Enter') {
                                        e.preventDefault();
                                        addToUserQueryList();
                                    }
                                }}
                            />
                            <button className="btn btn-success" type="button" onClick={addToUserQueryList}>Add</button>
                        </div>
                    </div>
                    {userQueryList && userQueryList.length > 0 && 
                        <div className='col-md-12'>
                            <div className='border border-dark rounded p-2'>
                                {userQueryList.map((intentMessage,i) =>
                                    <Chip key={i} label={intentMessage} className='me-1' onDelete={()=>removeFromUserQueryList(i)} />
                                )}
                            </div>
                        </div>
                    }
                    {userQueryList && userQueryList.length > 0 && 
                        <div className='col-md-12 mt-4'>
                            <AppSelect
                                options={[{"label":"--Select Response--",value:null},...responseDDList]}
                                value={selectedResponse}
                                setValue={setSelectedResponse}
                                label="Select Response" 
                                optionLabel = "label"
                            />
                        </div>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                {savingInProgress ? 
                    <CircularProgress/>
                    :
                    <button  
                        disabled={!userQueryList ||userQueryList.length == 0 || selectedResponse==null || selectedResponse.value==null } 
                        type="button" className='btn btn-primary'
                        onClick={onSubmit}
                    >
                        Save
                    </button>
                }
                
            </DialogActions>
        </Dialog>

    </>
    )
}

export default IntentForm