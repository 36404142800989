import { List, RadioButtonUnchecked } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import moment from 'moment'
import React,{useState} from 'react'

const TextMessage = ({message}) =>{
  return (
    <div className='card'>
      <div className='card-body'>
        {message.messageBody}
      </div>
    </div>
  )
}

const InteractiveReplyMessage = ({message}) =>{
  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <h5>{message.headerText}</h5>
          <h6>{message.bodyText}</h6>
          <h6 className='text-muted'>{message.footerText}</h6>
        </div>
      </div>
      {message.optionList && message.optionList.map(option=>
					<button className='btn btn-dark bg-white text-dark m-2'>{option.optionTitle}</button>
			)}
    </>
  )
}

const InteractiveListMessage = ({message}) =>{
  const [dialogStatus, setDialogStatus] = useState(false);
  return (
    <div className='card'>
      <div className='card-body'>
				<h5>{message.headerText}</h5>
				<h6>{message.bodyText}</h6>
				<h6 className='text-muted'>{message.footerText}</h6>
			</div>
      
      <div className='card-footer text-center'>
				<button className='btn btn-outline-primary' onClick={()=>setDialogStatus(true)} ><List/> {message.menuButtonTitle}</button>		
      </div>

                          <Dialog
                            open={dialogStatus}
                            keepMounted
                            onClose={()=>setDialogStatus(false)}
                            maxWidth="sm"
                            fullWidth={true}
                          >
                            <DialogTitle>{message.optionListTitle}</DialogTitle>
                              <DialogContent>
                                <ul class="list-group">
                                {message.optionList && message.optionList.map(option=>
                                  <li class="list-group-item d-flex justify-content-between align-items-center">
                                      <span>
                                      <div className='fs-5'>{option.optionTitle}</div>
                                      {option.optionDescription !== "" && <div>{option.optionDescription}</div> }
                                    </span>
                                      <span><RadioButtonUnchecked /></span>
                                    </li>
                                )}
                                </ul>
                              </DialogContent>
                          </Dialog>
    </div>
  )
}


function OutgoingMessageCard({message,dateTime}) {
  return (
    <div className="d-flex justify-content-end ms-5 my-2">
        <div>
            {message.messageBody !== undefined && <TextMessage message={message}/>}
            {message.messageBody == undefined && message.menuButtonTitle === undefined && <InteractiveReplyMessage message={message} /> }
            {message.messageBody == undefined && message.menuButtonTitle !== undefined && <InteractiveListMessage message={message} /> }

            <div className='float-end text-muted'>
              {moment(dateTime).fromNow()}
            </div>
        </div>
    </div>
  )
}

export default OutgoingMessageCard