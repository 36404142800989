import React, { useState,useEffect } from 'react'
import responseMessageServices from '../responseMessageServices';
import AppSelect from '../../../../components/AppInput/AppSelect';
import { useAlert } from '../../../../context/AlertContext';
import { useNavigate } from 'react-router-dom';
import { Delete, RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';

function InteractiveReplyMessageForm({data}) {
	const {AppAlert} = useAlert();
	const [title,setTitle] = useState("");

	const [headerType,setHeaderType] = useState("text");
	const [headerText,setHeaderText] = useState("");
	const [headerImage,setHeaderImage] = useState("");

	const [bodyText,setBodyText] = useState("");
	const [footerText,setFooterText] = useState("");
	const [optionTitle,setOptionTitle] = useState("");
	const [selectedResponse,setSelectedResponse] = useState(null);
	const [optionList,setOptionList]	= useState([]);
	

	const [responseDDList,setResponseDDList] = useState();
	const [loading,setLoadingStatus] = useState(false)


	const autoFillData = ()=>{
		if(data){
			setTitle(data.title)

			setHeaderType(data.formValues.headerType);
			setHeaderText(data.formValues.headerText);
			setHeaderImage(data.formValues.headerImage);

			setBodyText(data.formValues.bodyText);
			setFooterText(data.formValues.footerText);
			setOptionList(data.formValues.optionList);
		}
	}
	useEffect(()=>{
		autoFillData();
	},[data])

	const getResponseDDList = () =>{
		setLoadingStatus(true)
		responseMessageServices.getResponseDDList().then(res=>{
			

			if(data) {
				let _ddList  = res.data.filter(dd => dd.value != data.id);
				setResponseDDList(_ddList)
			} else {
				setResponseDDList(res.data);
			}

		}).catch(error=>{
		}).finally(()=>{
			setLoadingStatus(false)
		})
	}
	useEffect(()=>{
		getResponseDDList();
	},[])

	const addOptiontoList = () =>{
		let _optionList = [...optionList];
		let item = {
			"optionTitle" : optionTitle,
			"selectedResponse" : selectedResponse, 
		}
		setOptionList([..._optionList,item]);
		setOptionTitle("");
		setSelectedResponse(null);
	}

	const removeOption = (i) =>{
		let _optionList = [...optionList];
        _optionList.splice(i,1)
        setOptionList(_optionList)
	}

	const prepareReplyResponse = () =>{
		let _responseMessage = {
			"type": "button",
			"body": {
				"text":bodyText
			},
		}
		if(headerType === "text"){
			if(headerText!==""){
				_responseMessage.header ={
					"type":"text",
					"text": headerText
				}
			}	
		} 
		if(headerType === "image"){
			if(headerImage!==""){
				_responseMessage.header ={
					"type":"image",
					"image": {
						"link" : headerImage
					}
				}
			}
		}
		
		
		if(footerText!==""){
			_responseMessage.footer ={
				"text": footerText
			}
		}
		let _optionList = [];
		optionList.forEach(option => {
			let _option = {
				"type" :"reply",
				"reply": {
					"id" :option.selectedResponse.value,
					"title": option.optionTitle
				}
			}
			_optionList.push(_option);
		});
		let _action = {
			"buttons":_optionList
		}
		_responseMessage.action = _action;
		return _responseMessage;
	}

	const prepareWhatsAppResponse = () =>{
		let _whatsAppResponse = {
			"messaging_product": "whatsapp",
    		"recipient_type": "individual",
			"type": "interactive",
			"interactive":prepareReplyResponse()
		}
		return JSON.stringify(_whatsAppResponse);
	}

	const prepareFormValues = () =>{
		let _formValues = {
			"headerType" :headerType,
			"headerText" :headerText,
			"headerImage" :headerImage,
			"bodyText":bodyText,
			"footerText":footerText,
			"optionList" : optionList
		}
		return JSON.stringify(_formValues);
	}

	const navigate = useNavigate();
	const [saving,setSavingStatus] = useState(false)
	const onSubmit = () =>{
		let payload = {
			"title":title,
			"messageType":"interactiveReply",
			"whatsAppResponse": prepareWhatsAppResponse(),
			"formValues":prepareFormValues()
		}
		console.log(payload);
		if(data){
			payload.id = data.id;
		}
		setSavingStatus(true);
		responseMessageServices.save(payload).then(res=>{
			AppAlert(res.msg)
			if(data){
				navigate("/admin/response-message/form/"+data.id)
			} else{
				navigate("/admin/response-message/form/"+res.id)
			}
			
		}).catch(error=>{
			AppAlert(error.msg,"error");
		}).finally(()=>{
			setSavingStatus(false);
		})
	}

	return (
    	<div className='row'>
			<div className='col-md-9'>
				<div className='card'>
					<div className='card-header'>Intractive Message With Reply Button</div>
					<div className='card-body'>
						<div className='row'>
							<div className='col-md-6'>
								<div className="form-floating mb-3">
									<input type="text" className="form-control" placeholder="" value={title}  onChange={(e)=>setTitle(e.target.value)}/>
									<label>Message Title (For Admin / Manager Use) *</label>
								</div>
							</div>
						</div>

						<hr/>

						<div className='row'>
							<div className='col-md-12 mb-3'>
								<span className=' me-3'>Header Type : </span>
								<button className={ headerType === "text"  ? 'btn btn-primary me-2' : 'btn btn-outline-primary me-2' } onClick={ () =>setHeaderType("text")}>
									{headerType === "text" ? <RadioButtonChecked/> : <RadioButtonUnchecked/> }
									Text
								</button>
								<button className={ headerType === "image" ? 'btn btn-primary me-2' : 'btn btn-outline-primary me-2' } onClick={ () => setHeaderType("image")}>
									{headerType === "image" ? <RadioButtonChecked/> : <RadioButtonUnchecked/> }
									Image
								</button>
							</div>
							<div className='col-md-12 mb-3'>
								{ headerType === "text" ?
									<div className="form-floating">
										<input type="text" className="form-control" placeholder="" 
											value={headerText}  onChange={(e)=>setHeaderText(e.target.value)}
										/>
										<label>Header Text</label>
									</div>
									:
									<div className="form-floating">
										<input type="text" className="form-control" placeholder="" 
											value={headerImage}  onChange={(e)=>setHeaderImage(e.target.value)}
										/>
										<label>Header Image Url *</label>
									</div>
								}
								
							</div>
							<div className='col-md-12'>
								<div className="form-floating">
									<textarea className="form-control" placeholder="" style={{height:"100%"}} 
										value={bodyText}  onChange={(e)=>setBodyText(e.target.value)}
										maxLength={1024}
									></textarea>
									<label>Message Body * <span className='text-danger'>( {1024-bodyText.length} )</span></label>
								</div>
							</div>
							<div className='col-md-12'>
								<div className="form-floating">
									<input type="text" className="form-control" placeholder="" 
										value={footerText}  onChange={(e)=>setFooterText(e.target.value)}
										maxLength={60}
									/>
									<label>Footer Text <span className='text-danger'>( {60-footerText.length} )</span></label>
								</div>
							</div>
						</div>
						<hr/>
						<h5>Available Options</h5>
						{responseDDList && responseDDList.length > 0 && optionList?.length < 3 && 
							<div className='row'>
								<div className='col-md-5'>
									<div className="form-floating">
										<input type="text" className="form-control" placeholder="" 
											value={optionTitle}  onChange={(e)=>setOptionTitle(e.target.value)}
											maxLength={20}
										/>
										<label>Button Caption * <span className='text-danger'>( {20-optionTitle.length} )</span> </label>
									</div>
								</div>
								<div className='col-md-5'>
									<AppSelect
										options={[{"label":"--Select Response--",value:null},...responseDDList]}
										value={selectedResponse}
										setValue={setSelectedResponse}
										label="Select Response *" 
										optionLabel = "label"
									/>
								</div>
								{
								<div className='col-md-2'>
									<button 
										disabled={selectedResponse === null || selectedResponse?.value == null || optionTitle === "" } 
										className='btn btn-primary btn-lg' onClick={addOptiontoList}
									>
										Add
									</button>
								</div>
								}
							</div>
						}
						{responseDDList && optionList?.length > 0 &&
							<div className='row mt-2'>
								<div className='col-md-12'>
									<div className='card'>
										<div className='card-body bg-light'>
											<table className='table'>
												<thead>
													<tr>
														<th>Button Title</th>
														<th>Selected Response</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{optionList.map((option,i)=>
														<tr key={i}>
															<td>{option.optionTitle}</td>
															<td>{option.selectedResponse.label}</td>
															<td><button className='btn btn-danger btn-sm' onClick={()=>removeOption(i)}><Delete/> </button></td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						}
					</div>
					<div className='card-footer'>
						{saving ? 
							<LinearProgress/>
							:
							<button 
								disabled={title=="" || bodyText == "" || optionList.length == 0}
								className='btn btn-primary btn-lg float-end'
								type='button'
								onClick={onSubmit}
							>
								Save
							</button>
						}
					</div>
				</div>
			</div>
			<div className='col-md-3'>
				<h3 className='mx-3'>Preview</h3>
				<div className='bg-light border border-dark rounded p-2 mx-3 ps-5' style={{height:"70vh"}}>
						<div className='card'>
							{headerType === "image" && <img src={headerImage} class="card-img-top"/>}
							<div className='card-body'>
								{headerType === "text" && <h5>{headerText}</h5> }
								<h6>{bodyText}</h6>
								<h6 className='text-muted'>{footerText}</h6>
							</div>
						</div>
						{optionList && optionList.map(option=>
							<button className='btn btn-dark bg-white text-dark m-2'>{option.optionTitle}</button>
						)}
						
						
				</div>
			</div>
		</div>
	)
}

export default InteractiveReplyMessageForm