import { LinearProgress } from '@mui/material';
import React,{useState,useEffect} from 'react'
import { useAlert } from '../../../../context/AlertContext';
import { useNavigate } from 'react-router-dom';
import responseMessageServices from '../responseMessageServices';

function LocationMessageForm({data}) {
    const {AppAlert} = useAlert();
    const [title,setTitle] = useState(""); 
    const [saving,setSavingStatus] = useState(""); 
    
    const [locationName,setLocationName] = useState(""); 
    const [locationAddress,setLocationAddress] = useState(""); 
    const [latitude,setLatitude] = useState(""); 
    const [longitude,setLongitude] = useState(""); 


    const autoFillData = ()=>{
		if(data){
			setTitle(data.title)
		    setLocationName(data.formValues.locationName);
            setLocationAddress(data.formValues.locationAddress);
            setLatitude(data.formValues.latitude);
            setLongitude(data.formValues.longitude);
		}
	}
	useEffect(()=>{
		autoFillData();
	},[data])


    const prepareFormValues = () =>{
		let _formValues = {
			"latitude": latitude,
            "longitude": longitude,
            "locationName": locationName,
            "locationAddress": locationAddress
		}
		return JSON.stringify(_formValues);
	}

    const prepareLocationResponse = () =>{
		let _responseMessage = {
            "latitude": latitude,
            "longitude": longitude,
            "name": locationName,
            "address": locationAddress
          }
		return _responseMessage;
	}

	const prepareWhatsAppResponse = () =>{
		let _whatsAppResponse = {
			"messaging_product": "whatsapp",
    		"recipient_type": "individual",
			"type": "location",
			"location":prepareLocationResponse()
		}
		return JSON.stringify(_whatsAppResponse);
	}

    const navigate = useNavigate();
    const onSubmit = () =>{   
        let payload = {
			"title":title,
			"messageType":"locationMessage",
			"whatsAppResponse": prepareWhatsAppResponse(),
			"formValues":prepareFormValues()
		}

        if(data){
			payload.id = data.id;
		}

        setSavingStatus(true);
		responseMessageServices.save(payload).then(res=>{
			AppAlert(res.msg)
			if(data){
				navigate("/admin/response-message/form/"+data.id)
			} else{
				navigate("/admin/response-message/form/"+res.id)
			}
			
		}).catch(error=>{
			AppAlert(error.msg,"error");
		}).finally(()=>{
			setSavingStatus(false);
		})

    }


    return (
        <div className='row'>
			<div className='col-md-9'>
				<div className='card'>
					<div className='card-header'>Location Message Response</div>
					<div className='card-body'>
						<div className='row'>
							<div className='col-md-6'>
								<div className="form-floating mb-3">
									<input type="text" className="form-control" placeholder="" value={title}  onChange={(e)=>setTitle(e.target.value)}/>
									<label>Message Title (For Admin / Manager Use) *</label>
								</div>
							</div>
							<div className='col-md-12'>
							<div className="form-floating">
                                <div className="form-floating mb-3">
									<input type="text" className="form-control" placeholder="" value={locationName}  onChange={(e)=>setLocationName(e.target.value)}/>
									<label>Location Name *</label>
								</div>

                                <div className="form-floating mb-3">
									<input type="text" className="form-control" placeholder="" value={latitude}  onChange={(e)=>setLatitude(e.target.value)}/>
									<label>Latitude *</label>
								</div>

                                <div className="form-floating mb-3">
									<input type="text" className="form-control" placeholder="" value={longitude}  onChange={(e)=>setLongitude(e.target.value)}/>
									<label>Longitude *</label>
								</div>

                                <div className="form-floating">
                                    <textarea className="form-control" placeholder="" style={{height:"100%"}} 
                                        value={locationAddress}  onChange={(e)=>setLocationAddress(e.target.value)}
                                    ></textarea>
								<label>Location Address *</label>
							</div>

							</div>
							</div>
						</div>
					</div>
					<div className='card-footer'>
						{saving ? 
							<LinearProgress/>
							:
							<button disabled={title === "" || locationName === "" || locationAddress === "" || latitude === "" || longitude === "" } className='btn btn-primary float-end' onClick={onSubmit} >Save</button>
						}
					</div>
				</div>
			</div>
			<div className='col-md-3'>
				<h3 className='mx-3'>Preview</h3>
				<div className='bg-light border border-dark rounded p-2 mx-3 ps-5' style={{height:"70vh"}}>
					<div className='card'>
						<img src="/images/map-ref.jpg" class="card-img-top"/>
						<div className='card-body'>
                            <h5>{locationName}</h5>
							<h6>{locationAddress}</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default LocationMessageForm