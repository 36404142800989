import {useState,useEffect} from 'react'
//import kioskInfoServices from '../app/kiosk/infoKiosk/kioskInfoServices';

function useBusinessInfo(masterNameList,type) {
    const [businessInfo,setBusineeInfo] = useState(null);
    const getBusinessInfo = () =>{
        let _businessInfo  =  JSON.parse(sessionStorage.getItem("businessInfo"));
        if(_businessInfo == null){
            console.log("get business info from use")
            /*kioskInfoServices.getBusinessInfo().then(res=>{
                setBusineeInfo(res.data);
                sessionStorage.setItem("businessInfo",JSON.stringify(res.data))
            })
            */
        } else {
            //console.log("_businessInfo is ",_businessInfo)
            setBusineeInfo(_businessInfo);
        }
    }
    useEffect(()=>{
        getBusinessInfo();
    },[])
    return businessInfo;
}

export default useBusinessInfo