import { Alert, Snackbar } from '@mui/material';
import React,{useState,useContext} from 'react'
const AlertContext = React.createContext();

export const useAlert = () => {
    return useContext(AlertContext);
}

export const AlertProvider = (props) =>{

    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");
    const AppAlert = (message,messageType="success") =>{
        setSnackStatus(true);
        setSnackType(messageType);
        setSnackMessage(message);
    }
    const value= {
        AppAlert,
    }

    return (
        <AlertContext.Provider value= {value}>
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={5000}  
                anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert 
                    onClose={()=>setSnackStatus(false)}
                    severity={snackType} sx={{ width: '100%' }}> {snackMessage}
                </Alert>
            </Snackbar>
                {props.children}
        </AlertContext.Provider>
    )
}