import React from 'react'
import AppLayout from '../../../../layout/components/AppLayout'

function MediaLibraryPage() {
    return (
        <AppLayout>

            <div>MediaLibraryPage</div>
        </AppLayout>
    )
}

export default MediaLibraryPage