import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import IntentForm from '../components/IntentForm'
import intentServices from '../intentServices';
import { useAlert } from '../../../../context/AlertContext';
import { CircularProgress } from '@mui/material';

function IntentListPage() {
	const {AppAlert} = useAlert();
	const [responseDDList,setResponseDDList] = useState();
	const [loading,setLoadingStatus] = useState(false)
	const getResponseDDList = () =>{
		setLoadingStatus(true)
		intentServices.getResponseDDList().then(res=>{
			setResponseDDList(res.data);
		}).catch(error=>{
		}).finally(()=>{
			setLoadingStatus(false)
		})
	}
	useEffect(()=>{
		getResponseDDList();
	},[])

	const getResponseLabel = (id) => {
        try{
            let label =responseDDList.find(r => r.value === id)
            return label.label;
        } catch(e){
            //console.log(type,id);
            return "";
        }
    }


	const [intentMessageList,setIntentMessageList] = useState(null)
	const getIntentMessageList = () => {
		setLoadingStatus(true);
		intentServices.getList().then(res=>{
			setIntentMessageList(res.data);
		}).catch(error=>{
			AppAlert(error.msg,"error")
		}).finally(()=>{
			setLoadingStatus(false);
		})
	}

	useEffect(()=>{
		getIntentMessageList()
	},[])

	return (
		<AppLayout>
			{responseDDList &&
			<div className='container-fluid'>
				<div className='card'>
					<div className='card-header'>
						<h4 className='float-start'>Intent List (User Queries)</h4>
						<sapn className="float-end"><IntentForm responseDDList={responseDDList} onSuccess={getIntentMessageList} /></sapn>
					</div>
					<div className='card-body'>
						{loading && <div className='text-center m-5'><CircularProgress/></div>}
						{!loading && intentMessageList?.length > 0 &&
							<div className=''>
								<table className='table'>
									<thead>
										<tr>
											<th>User Query</th>
											<th>Response</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										{intentMessageList.map(intent=>
											<tr key={intent.id}>
												<td>{intent.userQuery}</td>
												<td>{ getResponseLabel(intent.responseMessageId)}</td>
												<td><IntentForm data={intent} responseDDList={responseDDList} onSuccess={getIntentMessageList} /></td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
						}
					</div>
				</div>
			</div>
			}
		
		</AppLayout>
	)
}

export default IntentListPage