import api from "../../../api/api";

const getConversationList = () =>{
    return new Promise((resolve, reject)=>{
        api.get("admin/chat-log/conversation/list").then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const getConversationDetails = (phoneNumber) =>{
    return new Promise((resolve, reject)=>{
        api.get("admin/chat-log/conversation/details/"+phoneNumber).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    msg: res.data.msg
                })
            }
        }).catch( (error) => {
            reject({
                msg: "Something Went Wrog",
                error : error
            })
        })
    })
}

const chatLogServices = {
    getConversationList,
    getConversationDetails
}

export default chatLogServices;