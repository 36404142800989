import React, { useEffect, useState } from 'react'
import AppLayout from '../../../../layout/components/AppLayout'
import responseMessageServices from '../responseMessageServices';
import { useAlert } from '../../../../context/AlertContext';
import moment from 'moment';
import { Link } from 'react-router-dom';

function ResponseMessageListPage() {
  const {AppAlert} = useAlert()
  const [responseMessageList,setResponseMessageList] = useState(null);
  const [loading,setLoadingStatus] = useState(false);
  const getResponseMessageList = () =>{
    setLoadingStatus(true);
    responseMessageServices.getList().then(res=>{
      setResponseMessageList(res.data);
    }).catch(error=>{
      AppAlert(error.msg,"error")
    }).finally(()=>{
      setLoadingStatus(false)
    })
  }

  const markAsDefaultResponse = (id) =>{
    setLoadingStatus(true);
    responseMessageServices.markAsDefault(id).then(res=>{
      getResponseMessageList();
    }).catch(error=>{
      AppAlert(error.msg,"error")
    }).finally(()=>{
      setLoadingStatus(false)
    })
  }

  useEffect(()=>{
    getResponseMessageList();
  },[])

  return (
    <AppLayout>
        <div className='container-fluid'>
          <div className='card'>
            <div className='card-header'>
              <h5 className='float-start'>Response Messages List</h5>
              <Link className='btn btn-primary float-end' to={"/admin/response-message/form"}> Create New Response</Link>
            </div>
            <div className='card-body'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Message Title</th>
                    <th>Message</th>
                    <th>Last Updated At</th>
                    <th>Created</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {responseMessageList && responseMessageList.map(responseMessage=>
                    <tr>
                      <td>
                        {responseMessage.title}
                        {responseMessage.defaultResponseId==1 && <span className='badge bg-primary ms-2'>Default Message</span>}
                      </td>
                      <td>
                        {responseMessage.messageType === "text" ?
                            <p>{JSON.parse(responseMessage.formValues).messageBody}</p>
                          :
                            <p>{responseMessage.messageType}</p>
                        }
                      </td>
                      <td>{moment(responseMessage.updated_at).fromNow()}</td>
                      <td>{moment(responseMessage.created_at).fromNow()}</td>
                      <td>
                        {responseMessage.defaultResponseId !==1 &&
                          <button type="button" className='btn btn-success me-2' onClick={()=>markAsDefaultResponse(responseMessage.id)} >Set As Default Response</button>
                        }
                        <Link className='btn btn-primary' to={"/admin/response-message/form/"+responseMessage.id}> Edit</Link>
                        </td>
                      
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </AppLayout>
    
  )
}

export default ResponseMessageListPage