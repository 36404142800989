import React,{useState,useEffect,useContext} from 'react'
const AuthContext = React.createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = (props) =>{
    const [authUser,setAuthUser] = useState(null);
    const [isLoggedIn,setIsLoggedIn] = useState(false); 
    const [loading,setLoadingStatus] = useState(true);
    const value= {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    }

    useEffect(()=>{
        let user = JSON.parse(localStorage.getItem("authUser"));
        if(user !== null){
            setAuthUser(user);
            setIsLoggedIn(true);
        }
        setTimeout(()=>{
            setLoadingStatus(false);
        },5000)
    },[])
    return <AuthContext.Provider value= {value}>{props.children}</AuthContext.Provider>
}