import axios from 'axios';
import { useNavigate } from 'react-router-dom';

//const baseURL = process.env.REACT_APP_BASE_URL;


let portalUrl = localStorage.getItem("portalUrl");
let portalId = localStorage.getItem("portalId");
const baseURL = portalUrl;

const API = axios.create({
    baseURL: baseURL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With' : portalId
      },
});

API.interceptors.request.use(
    config => {
        if(localStorage.authUser){  // If user is Loggedin 
            let authUser = JSON.parse(localStorage.authUser);
            let token = authUser.token;
            config.headers.Authorization = `Bearer ${token}`;
            //config.headers['X-Requested-With'] = 1;
      } else {
        delete API.defaults.headers.common.Authorization;
      }
      return config;
    },
    error => Promise.reject(error)
);

API.interceptors.response.use(response=> {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  //console.log("response is");
  return response;
}, function (error) {
  
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log("error received",error.response.status);
  if(error.response.status === 401){
    //window.location.href = window.location.origin+"/401";
  }
  return Promise.reject(error);
});

export default API;